@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400&family=Fauna+One&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  margin: auto;
}
